<template>
  <div class="root">
    <el-row class="header-block">
      <el-col :span="16" class="search-filter-root">
        <div class="grid-content">
          <el-form @submit.native.prevent>
            <div class="el-input">
              <input
                id="searchInput"
                placeholder="Try a location or club name"
                v-model="searchInput"
                class="el-input__inner rounded"
                style="padding-left: 16px"
              />
              <span class="el-input__suffix">
                <span class="el-input__suffix-inner">
                  <button class="icon-wrapper search-button" @click.prevent="fetchSearchData">
                    <i class="icon-search"></i>
                  </button>
                </span>
              </span>
            </div>
          </el-form>
        </div>
      </el-col>
      <el-col :span="8" class="search-filter-root">
        <el-col :span="12">
          <div class="filter" @click="toggleVisible(true)">
            <i class="icon-filter" />
          </div>
        </el-col>
        <el-col :span="12">
          <div class="list" @click="toggleMap(!mapVisible)">
            <i v-if="!mapVisible" class="icon-map" />
            <i v-if="mapVisible" class="icon-list" />
          </div>
        </el-col>
        <div v-if="showFilterButtons" class="filters">
          <!-- below copied from element -->
          <ModalLayoutRelative
            v-if="filterVisible"
            class="modal-parent"
            @close="handleCancelFilters"
            title="FILTERS"
          >
            <FilterPage
              @submit="handleSubmitFilters"
              :value="appliedFiltersSync"
              @cancel="handleCancelFilters"
              @clear="handleClearFilters"
            />
          </ModalLayoutRelative>
          <div v-if="hasPrograms" class="select-2">
            <el-select
              v-model="lengthValue"
              placeholder="DURATION"
              class="full-width"
              :class="lengthValue ? 'has-value' : null"
            >
              <el-option
                v-for="item in lengthChoices"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <div class="append-icon-close" v-show="lengthValue" @click="lengthValue = ''">
              <i class="icon-close-green"></i>
            </div>
          </div>
          <div v-if="hasPrograms" class="select-3">
            <el-select
              v-model="daysValue"
              placeholder
              class="mt-0 text-days full-width"
              multiple
              :style="dayOfTheWeekStyle"
              :class="daysValue.length !== 0 ? 'has-value' : null"
            >
              <el-option
                v-for="item in daysChoices"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <div class="append-icon-close" v-show="daysValue.length !== 0" @click="daysValue = []">
              <i class="icon-close-green"></i>
            </div>
          </div>
        </div>
      </el-col>
      <el-tag
        v-for="tag in appliedFiltersReal.participantTypes"
        :key="tag"
        size="small"
        closable
        color="#00DB001A"
        @close="handleUpdateFilter(tag)"
      >
        {{ tag }}
      </el-tag>
    </el-row>
    <el-row>
      <div v-if="!mapVisible && entities">
        <el-col class="content card-list-root">
          <p class="sort-text">Sorted by closest to search location</p>
          <div class="tabs">
            <div class="tab-nav">
              <div
                class="tab"
                v-for="(tab, index) in tabs"
                :key="index"
                :class="{ active: activeTab === index }"
                @click="activeTab = index"
              >
                {{ tab.name }}
              </div>
            </div>
            <div class="tab-content">
              {{ activeContent }}
            </div>
          </div>
          <!-- important: area for search results   -->
          <div v-if="renderContent.length === 0" class="no-results">
            No results found. Please try with different location/name or update filters.
          </div>
          <Card
            :id="`location_${object._id}`"
            v-for="(object) in renderContent"
            :object="object"
            :key="`${object._id}+${(Math.random() + 1).toString(36).substring(7)}`"
            :ref="`location-${object._id}`"
            :locality="`${entities.locality}`"
            @submit="submit(object)"
            class="card"
          />
          <CompetitionCard
          :id="`location_${object._id}`"
          v-for="(object) in renderContent"
          :object="object"
          :locality="`${renderContent.locality} ${searchInput}`"
          :key="`${object._id}+${(Math.random() + 1).toString(36).substring(7)}`"
          :ref="`location-${object._id}`"
          @submit="submit(object)"
          class="card"
        />
        </el-col>
      </div>
      <el-col v-show="mapVisible">
        <Map
          :renderContent="renderContent"
          @updateSearchInput="updateSearchInput"
          @scrollToCard="scrollToCard"
          :isMobile="true"
        />
      </el-col>
    </el-row>
    <div class="padding-lr-10-sm-and-up cant-find-card">
      <el-card class="mt-2 d-flex-column flex-0 align-text-center" v-if="cantFind">
        <div class="flex-1 align-text-center m-1">Can't find what you are looking for?</div>
        <el-button
          @click="handleCantFind"
          type="success"
          class="full-width button-light button-thin mw-300"
          >Get in contact</el-button
        >
      </el-card>
    </div>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import flow from "lodash/fp/flow";
import map from "lodash/fp/map";
import flatMap from "lodash/fp/flatMap";
import reduce from "lodash/fp/reduce";
import find from "lodash/fp/find";
import filter from "lodash/fp/filter";
import omitBy from "lodash/fp/omitBy";
import isEmpty from "lodash/isEmpty";
import range from "lodash/range";
import { mapActions, mapGetters } from "vuex";
import Card from "@/components/card/CardBeta.vue";
import {
  daysChoices,
  lengthChoices,
  ageLevels,
  memberTypes,
  activity,
  competitionType,
  gender,
} from "@/utils/constants";
import ModalLayoutRelative from "@/layouts/ModalLayoutRelative.vue";
import FilterPage from "@/components/filters/FilterPage.vue";
import msg from "@/utils/constants/msg";
import Map from "@/components/map/Map.vue";
import CompetitionCard from '@/components/card/CompetitionCardBeta.vue';

const appliedFiltersOriginal = {
  participantTypes: [],
  activities: [],
  genders: [],
  ages: [],
  competitionTypes: [],
};

export default {
  name: "ClubSearchMobileView",
  components: {
    FilterPage,
    ModalLayoutRelative,
    Card,
    CompetitionCard,
    Map
  },
  props: {
    criteria: String,
    type: Array,
    activity: Array,
    gender: String,
    agemin: Number,
    agemax: Number,
    comptype: Array,
    source: String,
    entity: Boolean,
    entitytype: String,
  },
  computed: {
    ...mapGetters("user", ["isAuthenticated"]),
    ...mapGetters("registration", ["current"]),
    activeContent() {
      if (this.entities)
        switch (this.activeTab) {
          case 0:
            this.renderContent = this.entities.programsData.concat(
              this.entities.clubsData,
              this.entities.associationsData,
              this.entities.teamsData
            );
            return;
          case 1:
            this.renderContent = this.entities.programsData;
            return;
          case 2:
            this.renderContent = this.entities.clubsData;
            return;
          case 3:
            this.renderContent = this.entities.associationsData;
            return;
          case 4:
            this.renderContent = this.entities.teamsData;
            return;
          case 5:
            this.renderContent = this.entities.competitionsData;
            return;
          default:
            this.renderContent = [];
            return;
        }
    },
    concatFilters() {
      const a = Object.values(this.appliedFiltersReal);
      return a.reduce((total, item) => total.concat(item), []);
    },
    filterText() {
      const result = this.concatFilters;
      if (result.length === 1 && result[0] !== "All") {
        return result[0].toUpperCase();
      }
      return "FILTER";
    },
    dayOfTheWeekStyle() {
      const { daysValue } = this;
      const txt = daysValue.length === 1 ? daysValue[0] : "DAY OF THE WEEK";

      return {
        "--content": `"${txt}"`.toUpperCase(),
        "--color": daysValue.length !== 0 ? "#098255" : "grey",
      };
    },
    currentAgeRange() {
      const ageLevel = find((o) => o.name === this.appliedFiltersReal.ages[0])(ageLevels);
      if (ageLevel) return { min: ageLevel.min, max: ageLevel.max };
      return {};
    },
    mappedParticipantType() {
      return flow(
        flatMap((val) => filter((o) => o.name.split(" ")[0] === val)(memberTypes)),
        map((obj) => obj.type)
      )(this.appliedFiltersReal.participantTypes);
    },
    showFilterButtons() {
      return this.entities?.length !== 0 || this.concatFilters.length !== 0;
    },
    hasPrograms() {
      return reduce((entity) => entity.entityType === "program")(this.entities).length !== 0;
    },
  },
  methods: {
    ...mapActions("registration", ["updateCurrent"]),

    updateSearchInput(data) {
      this.searchInput = data;
      this.fetchSearchData();
    },

    handleUpdateFilter(tag) {
      // remove tag from this.appliedFiltersReal.participantTypes
      const newParticipantTypes = this.appliedFiltersReal.participantTypes.filter(
        (item) => item !== tag
      );
      this.appliedFiltersReal.participantTypes = newParticipantTypes;
    },

    scrollToCard(location) {
      const card = this.$refs[`location-${location._id}`][0];
      card.$el.scrollIntoView({ behavior: "smooth" });
    },

    cloneDeep,
    handleCantFind() {
      // Expression of Interest for National Bodies
      this.$router
        .push({
          name: "expressinterest",
          params: { entityType: "national", entityId: 1 },
        })
        .catch(() => {});
    },
    submit(object) {
      this.updateCurrent({ entity: object });

      // Programs don't have regoOpen
      if (object.entityType === "program" && this.isAuthenticated) {
        this.$router.push({ name: "chooseprofile" });
      } else if (object.regoOpen && !object.teamInManyComps && this.isAuthenticated) {
        this.$router.push({ name: "chooseprofile" });
      } else if (object.regoOpen && !object.teamInManyComps) {
        this.$router
          .push({
            name: "loginrequired",
            params: { loginRedirect: "/register/chooseprofile" },
          })
          .catch((e) => {
            //   console.log("error");
          });
      } else if (object.entityType === "program") {
        this.$router
          .push({
            name: "loginrequired",
            params: { loginRedirect: "/register/chooseprofile" },
          })
          .catch((e) => {
            //  console.log("error");
          });
      } else {
        // Expression of Interest for Entities
        this.$router.push({
          name: "expressinterest",
          params: { entityType: object.entityType, entityId: object._id },
        });
      }
    },
    fetchSearchData() {
      this.cantFind = true;
      const nationalOrg = this.$store.getters["views/nationalOrg"];
      const body = omitBy(isEmpty)({
        source: nationalOrg ? nationalOrg.code : undefined,
        criteria: this.searchInput.split(",")[0],
        filters: omitBy(isEmpty)(
          Object.assign(
            {},
            this.appliedFiltersReal,
            { ages: this.currentAgeRange },
            { participantTypes: this.mappedParticipantType }
          )
        ),
        entityType: this.entityType,
      });

      body.isEntityIdSearch = this.isEntityIdSearch;

      this.$store.commit("root/LOADING", true);

      this.$httpms
        .post("/searchtoPlayBeta", body)
        .then((response) => {
          if (this.isEntityIdSearch && this.entityType === "program") {
            this.searchInput = "";
          }
          if (this.isEntityIdSearch) this.isEntityIdSearch = false;
          const result = response.data;
          if (result && result.data && result.data.length !== 0) {
            this.entities = result.data;
            this.renderContent = result.data.programsData.concat(
              result.data.clubsData,
              result.data.associationsData,
              result.data.teamsData,
              result.data.competitionsData
            );
          } else {
            this.entities = undefined;
          }
          this.$store.commit("root/LOADING", false);
        })
        .catch(() => {
          this.$store.commit(
            "views/PUSH_NOTIFICATION",
            {
              msg: msg.error.apiError,
              type: "warning",
            },
            { root: true }
          );
          this.entities = undefined;
        });
    },
    querySearchAsync(queryString, cb) {
      const { searchResult } = this;
      const results = queryString
        ? searchResult.filter(this.createFilter(queryString))
        : searchResult;

      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        cb(results);
      }, 500 * Math.random());
    },
    createFilter(queryString) {
      return (link) => link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
    },
    handleInput(item) {
      this.filterValue = item;
    },
    handleSubmitFilters(val) {
      this.appliedFiltersReal = cloneDeep(val);
      this.appliedFiltersSync = cloneDeep(val);
      this.toggleVisible(false);
      this.fetchSearchData();
    },
    handleCancelFilters() {
      this.appliedFiltersSync = cloneDeep(this.appliedFiltersReal);
      this.toggleVisible(false);
      this.fetchSearchData();
    },
    handleClearFilters() {
      this.appliedFiltersReal = cloneDeep(appliedFiltersOriginal);
      this.appliedFiltersSync = cloneDeep(appliedFiltersOriginal);
      this.toggleVisible(false);
      this.fetchSearchData();
    },
    toggleVisible(to) {
      this.filterVisible = to;
    },
    toggleMap(to) {
      this.mapVisible = to;
    },
  },
  mounted() {
    this.isEntityIdSearch = this.entity;
    const skeleton = cloneDeep(appliedFiltersOriginal);
    if (this.current.type) {
      switch (this.current.type) {
        case "player":
          skeleton.participantTypes.push("Player");
          break;
        case "coach":
          skeleton.participantTypes.push("Coach", "Trainer", "Volunteer");
          break;
        case "referee":
          skeleton.participantTypes.push("Referee");
          break;
        case "reregister":
        default:
      }
    } else {
      this.updateCurrent({ type: "player" });
    }
    if (this.criteria) {
      this.searchInput = this.criteria;
    }

    if (this.type) {
      const mappedTypes = memberTypes.filter((t) => this.type.includes(t.type)).map((t) => t.name);
      if (mappedTypes.length > 0) {
        skeleton.participantTypes.push(...mappedTypes);
        this.updateCurrent({ type: mappedTypes[0] });
      } else {
        this.updateCurrent({ type: "player" });
      }
    } else {
      this.updateCurrent({ type: "player" });
    }
    if (this.activity) {
      const foundActivities = this.activity.filter((param) => activity.includes(param));
      if (foundActivities.length > 0) {
        skeleton.activities.push(...foundActivities);
      }
    }
    if (this.gender) {
      const foundGender = gender.find((a) => a === this.gender);
      if (foundGender) skeleton.genders.push(foundGender);
    }
    if (this.agemin || this.agemax) {
      let ageLevel;
      if (this.agemin && this.agemax) {
        ageLevel = find((o) => o.min >= this.agemin && o.max <= this.agemax)(ageLevels);
      } else if (this.agemin) {
        ageLevel = find((o) => range(o.min, o.max + 1).includes(this.agemin))(ageLevels);
      } else if (this.agemax) {
        ageLevel = find((o) => range(o.min, o.max + 1).includes(this.agemax))(ageLevels);
      }
      if (ageLevel) skeleton.ages.push(ageLevel.name);
    }
    if (this.comptype) {
      const mappedCompTypes = this.comptype
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
        .filter((type) => competitionType.includes(type));
      if (mappedCompTypes.length > 0) {
        skeleton.competitionTypes.push(...mappedCompTypes);
      }
    }

    if (this.source) {
      // 'rugby-league' or 'touch-football'
      this.$store.dispatch("views/updateNationalOrg", this.source);
    }

    if (this.entitytype) {
      this.entityType = this.entitytype;
    }

    this.appliedFiltersOriginal = skeleton;
    this.appliedFiltersSync = skeleton;
    this.appliedFiltersReal = skeleton;

    if (this.searchInput) {
      this.fetchSearchData();
    }
  },
  data() {
    return {
      cantFind: false, // After search has been conducted
      // Search
      searchInput: "",
      searchTimeout: null,
      searchResult: [],
      // Filters
      filterVisible: false,
      mapVisible: true,
      filterValue: "",
      appliedFiltersOriginal,
      appliedFiltersSync: appliedFiltersOriginal, // Checkboxes
      appliedFiltersReal: appliedFiltersOriginal,
      lengthChoices,
      lengthValue: "",
      daysChoices,
      daysValue: [],
      entities: undefined,
      isEntityIdSearch: false,
      entityType: "",
      map: undefined,
      markers: [],
      infoWindow: undefined,
      currentLocation: undefined,
      activeTab: 0,
      renderContent: [],
      tabs: [
        { name: "All" },
        { name: "Programs" },
        { name: "Clubs" },
        { name: "Associations" },
        { name: "Teams" },
        { name: "Competitions"}
      ],
    };
  },
};
</script>

<style lang="scss">
.text-days::before {
  content: var(--content);
  color: var(--color);
  text-transform: uppercase;
  position: absolute;
  font-family: $fontRegular;
  font-size: 0.875rem;
  top: 10px;
  left: 15px;
  size: 0;
  z-index: 1;
}
</style>

<style lang="scss" scoped>
.append-icon-close {
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
  color: $secondary;
}

.has-value {
  // make it a cross
  ::v-deep i.icon-arrow-down {
    background: none;
  }

  i.icon-arrow-down {
    background: none;
  }

  ::v-deep .el-input__inner {
    height: calc(0.875rem + 26px);
    border-color: $secondary;
    color: $secondary;

    &::placeholder {
      color: $secondary !important;
    }
  }

  .el-input__inner {
    border-color: $secondary;

    &::placeholder {
      color: $secondary !important;
    }
  }
}

::v-deep .el-select {
  height: calc(0.875rem + 26px);
}

.el-input {
  .el-input__suffix {
    position: absolute;
    right: 8px;
    transition: 0.5s;
  }
  &.visible {
    .el-input__suffix {
      transform: rotate(-180deg);
    }
  }
}

.content {
  // height: 90vh;
  overflow: auto;
}

.select-1 {
  position: relative;
  width: 100%;
  @media (min-width: $sm) {
    max-width: 250px;
  }
}

.select-2 {
  position: relative;
  width: 50%;
  @media (min-width: $sm) {
    max-width: 200px;
  }
}

.select-3 {
  position: relative;
  width: 50%;
  @media (min-width: $sm) {
    max-width: 200px;
  }
}

.filters {
  padding-left: 10px;
  flex-wrap: wrap;
}

.min-1000 {
  min-height: 1400px;
}

::v-deep .el-input-group__append {
  padding: 0;
  border: 0;
}

.icon-wrapper {
  height: 40px;
  width: 55px;
}

.mw-50p {
  width: 100%;
  @media (min-width: 1263px) {
    max-width: 50%;
  }
}


.header-block {
  box-sizing: border-box;
  background: #ffffff;
  border-bottom: 1px solid #ededed;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  padding-left: 1em;
  padding-bottom: 1em;
}
.header-text {
  font-weight: 800;
  font-size: 20px;
  color: #222222;
  padding-top: 1em;
  // margin-bottom: 0;
}
.search-filter-root {
  padding-top: 2em;
}
.cant-find-card {
  margin-bottom: 2em;
}
.card-list-root {
  background: #f6f6f6;
  backdrop-filter: blur(4px);
  padding: 1.6em;
}
.sort-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}
.tabs {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: hidden;
}

.tab-nav {
  display: flex;
}

.tab {
  cursor: pointer;
  margin-right: 10px;
  background: #e6e6e6;
  border-radius: 100px;
  // min-width: 36px;
  padding-right: 1em;
  padding-left: 1em;
  font-size: 0.8em;
  line-height: 2.4em;
}

.tab.active {
  background: #00db00;
}

.tab-content {
  margin-top: 10px;
}
.grid-content {
  min-height: 36px;
}

.list {
  width: 40px;
  height: 40px;
  z-index: 999;
  margin-left: 8px;
  border-radius: 40px;
  background-color: #f6f6f6;
  // flex align center
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #e6e6e6;
  }
  // closed menu hamburger
  .icon-list {
    display: inline-block;
    background-image: url("../../../assets/icons/list.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 22px;
    height: 100%;
    padding: 10px;
  }
  .icon-map {
    display: inline-block;
    background-image: url("../../../assets/icons/map.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 22px;
    height: 100%;
    padding: 10px;
  }
}

.filter {
  width: 40px;
  height: 40px;
  z-index: 999;
  border-radius: 40px;
  background-color: #f6f6f6;
  margin-left: 16px;
  // flex align center
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #e6e6e6;
  }
  // closed menu hamburger
  .icon-filter {
    display: inline-block;
    background-image: url("../../../assets/icons/filter.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 22px;
    height: 100%;
    padding: 10px;
  }
}
.el-tag {
  margin-top: 1em;
  margin-right: 0.8em;
  color: "#000";
}
.no-results {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
}
.rounded {
  border-radius: 8px !important;
}
.search-button {
  background: none;
  margin-right: -12px;
  // padding: 0.5em 1em;
  font-weight: 600;
  font-size: 0.8em;
  line-height: 1.2em;
  border: none;
  cursor: pointer;
}
.icon-search {
  background-image: url("~@/assets/icons/search-grey.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.3125rem 1.25rem;
  padding: calc((1.25rem / 2) + 2px);
}
</style>
